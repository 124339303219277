/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useRef } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AudioPlayer from "react-h5-audio-player";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import QueueMusicIcon from '@material-ui/icons/QueueMusic';
import Minimize from "@material-ui/icons/Minimize";
import Maximize from "@material-ui/icons/Maximize";
import CloseIcon from "@material-ui/icons/Close";
import {
  closePlayer,
  playNextOrPrevious,
  toggle,
  toggleQueue,
  hideQueue
} from "../../store/slices/playerSlice";
import "react-h5-audio-player/lib/styles.css";
import "./player.css";
import { useHistory } from "react-router-dom";
import * as offlineAPI from "../../db/services";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.primary.main,

    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 0.5, 1, 0.5),
    },

    [theme.breakpoints.up("xs")]: {
      height: 120,
    },
  },

  minimizedRoot: {
    position: "fixed",
    bottom: 0,
    // left: 0,
    right: 0,
    backgroundColor: theme.palette.primary.main,

    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 0.5, 1, 0.5),
    },

    [theme.breakpoints.up("xs")]: {
      height: 100,
      width: 180,
    },
  },

  coverImage: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },

    [theme.breakpoints.up("xs")]: {
      height: 120,
      width: 120,
    },
  },

  player: {
    boxShadow: "none",
    backgroundColor: theme.palette.primary.main,
  },

  playerContainer: {
    width: "100%",
  },

  categoryTitle: {
    color: "white",
    cursor: "pointer",
    padding: theme.spacing(1, 0, 1, 0),
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
    },

    [theme.breakpoints.up("sm")]: {},
  },

  title: {
    color: "white",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1, 0, 1, 0),
      fontSize: 11,
    },

    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1, 0, 1, 0),
      fontSize: 14,
    },
  },
}));

export default function Player() {
  const [isMinmize, setIsMinimize] = useState(false);

  // const [isShowQueue,setIsShowQueue] = useState(false);

  const playerRef = useRef(null);

  const classes = useStyles();

  const { link, name, id, categoryId, open } = useSelector(
    (state) => state.player
  );
  const dispatch = useDispatch();
  const theme = useTheme();
  const history = useHistory();

  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const handleCloseButton = () => {
    dispatch(closePlayer());
    dispatch(hideQueue());
  };

  const handleToggleQueue = () => {
    // setIsShowQueue(!isShowQueue);
    dispatch(toggleQueue());

  };

  const handleMinimizeButton = () => {
    setIsMinimize(!isMinmize);
  };

  const handleNext = () => {
    dispatch(playNextOrPrevious("next"));
  };

  const handlePrevious = () => {
    dispatch(playNextOrPrevious("previous"));
  };

  const onCategoryClick = () => {
    history.push(`/category/${categoryId}`);
  };

  const togglePlayer = (status) => {
    dispatch(toggle(status));
  };

  const categoryName = useMemo(
    () => offlineAPI.getCategoryById(categoryId)?.name,
    [categoryId]
  );

  // add listner to detect the end of the audio

  useEffect(() => {
    const player = document.getElementsByTagName("audio")[0];
    if (player && !player?.onended) {
      player.onended = function (e) {
        handleNext();
      };
    }
  }, [id]);

  if (open) {
    return (

      <Box
        className={!isMinmize ? classes.root : classes.minimizedRoot}
        display="flex"
        zIndex={2}
      >
        {/* <QueueModal /> */}
        <Box className={classes.playerContainer}>
          <Box
            display="flex"
            justifyContent={isMinmize ? "end" : "space-between"}
            alignItems="center"
            px={1}
          >
            {!isMinmize && (
              <span>
                <Box display="flex" justifyContent="center" alignItems="center">
                  {categoryName && (
                    <Box
                      onClick={onCategoryClick}
                      className={classes.categoryTitle}
                      display="flex"
                      fontWeight="fontWeightBold"
                    >
                      {categoryName}
                      <div>&nbsp;-&nbsp;</div>
                    </Box>
                  )}
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.title}
                    component="div"
                    textOverflow="clip"
                    overflow="hidden"
                  >
                    {name}
                  </Box>
                </Box>
              </span>
            )}

            <Box display="flex" alignItems={"flex-start"}>
              <IconButton
                size="small"
                onClick={handleToggleQueue}
                // title={isShowQueue ? 'Hide Queue' : 'Show Queue'}
                title={'Toggle Queue'}
              >
                <QueueMusicIcon style={{ color: "white" }} />
              </IconButton>

              <IconButton
                size="small"
                onClick={handleMinimizeButton}
                title={isMinmize ? "Maximize player" : "Minimize player"}
              >
                {isMinmize ? (
                  <Maximize style={{ color: "white" }} />
                ) : (
                  <Minimize style={{ color: "white" }} />
                )}
              </IconButton>
              <Box display="flex" alignItems="flex-end">
                <IconButton
                  size="small"
                  onClick={handleCloseButton}
                  title="Close Player"
                >
                  <CloseIcon style={{ color: "white" }} />
                </IconButton>
              </Box>
            </Box>
          </Box>
          <div>
            {isMinmize ? (
              <div>
                <AudioPlayer
                  id="audio-player"
                  ref={playerRef}
                  showJumpControls={false}
                  showSkipControls={true}
                  style={{ width: "300px" }}
                  customVolumeControls={[]}
                  customProgressBarSection={[]}
                  customAdditionalControls={[]}
                  layout={isMobile ? "stacked" : "horizontal-reverse"}
                  className={classes.player}
                  autoPlay
                  src={link}
                  onClickNext={handleNext}
                  onClickPrevious={handlePrevious}
                  onPlay={() => togglePlayer(true)}
                  onPause={() => togglePlayer(false)}
                />
              </div>
            ) : (
              <div>
                {/* <p>Maximized</p> */}
                <AudioPlayer
                  id="audio-player"
                  ref={playerRef}
                  showJumpControls={false}
                  showSkipControls
                  layout={isMobile ? "stacked" : "horizontal-reverse"}
                  //customAdditionalControls={[]}
                  className={classes.player}
                  autoPlay
                  src={link}
                  onClickNext={handleNext}
                  onClickPrevious={handlePrevious}
                  onPlay={() => togglePlayer(true)}
                  onPause={() => togglePlayer(false)}
                  //crossOrigin="anonymous"
                />
              </div>
            )}
          </div>
        </Box>
      </Box>
    );
  } else return null;
}
