import React from "react";
import { useSelector } from "react-redux";
import TopBar from "../topBar";
import Player from "../player";
import QueueModal from "../QueueModal"

export default function Layout({ children }) {

  const { isShowQueue } = useSelector((state) => state.player)
    return (
        <div>
            <TopBar />
            <div id="app-main-content">
                {isShowQueue && <QueueModal />}
                {children}
                <Player />
            </div>
        </div>
    );
}
