// import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Box from "@material-ui/core/Box";
import { removeAudio } from "../../store/slices/playerSlice";
import styles from "./QueueModal.module.css";
import CloseIcon from "@material-ui/icons/Close";

export default function QueueModal() {

  const dispatch = useDispatch();
  const currentAudioList = useSelector((state) => state.player.currentAudioList);
  const {id} = useSelector((state) => state.player);

  // console.log(`currentAudioList: `, currentAudioList);
  // console.log(`id: `, id);

  function handleRemoveAudio(id) {
    // console.log(`removing: `, id);
    dispatch(removeAudio(id));


  };

  return (
    <div className={styles['queue-modal']}>
      <h1>Queue</h1>
      <div className={styles['queue-detail']}>
        {currentAudioList.map((list) => (
          <div
            key={list.id}
            >
            <div className={id === list.id ? 'bold-me': ''} >
              {list.name}
            </div>
            <span
              title="Remove audio"
              className={styles['close-btn']}
              onClick={() => handleRemoveAudio(list.id)}
              >
              <CloseIcon />
            </span>
          </div>

        ))}
      </div>
    </div>
  )
}
